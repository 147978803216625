<template>
    <div class="data">
        <Breadcrumb :currentPage="currentPage"></Breadcrumb>
        <TitleTabs :tabsData="tabsData" @changeTab="changeTab"></TitleTabs>
        <TabItems @changeItem="changeItem" :tabCurrentIndex="secondTabCurrentIndex"></TabItems>
        <Introduce @changeDownload="changeDownload" v-if="secondTabCurrentIndex===0" :introduce="introduceData"></Introduce>
        <Teach v-else-if="secondTabCurrentIndex===1" :teachData="teachData"></Teach>
        <Problem v-else-if="secondTabCurrentIndex===2" :problemData="problemData"></Problem>
        <Download v-else-if="secondTabCurrentIndex===3" :downloadData="downloadData"></Download>
    </div>
</template>

<script>
import Breadcrumb  from '../../components/Breadcrumb'
import TitleTabs from '../../components/TitleTabs'
import TabItems from '../../components/TabItems'
import Introduce from '../../components/Introduce'
import Teach from '../../components/Teach'
import Problem from '../../components/Problem'
import Download from '../../components/Download'
export default {
    name: 'Finance',

    data() {
        return {
            currentPage: '财务管理',
            firstTabCurrentIndex: 0,

            tabsData: [],
            secondTabCurrentIndex: 0,

            introduceData: {},

            teachData: {},

            problemData: {},

            downloadData: {}
        };
    },

    mounted() {
        this.getTitleTabs() 
        this.getFinanceIntroduce()
    },

    methods: {
        changeDownload() {
            this.secondTabCurrentIndex = 3
            this.getFinacneDownload()
        },

        changeTab(firstIndex) {

        },
        
        changeItem(secondIndex) {
            this.secondTabCurrentIndex = secondIndex
            if(this.secondTabCurrentIndex === 0) {
                this.secondTabCurrentIndex = 0
                this.getFinanceIntroduce()
            }else if(this.secondTabCurrentIndex === 1) {
                this.secondTabCurrentIndex = 1
                this.getFinanceTeach()
            }else if(this.secondTabCurrentIndex === 2) {
                this.secondTabCurrentIndex = 2
                this.getFinanceProblem()
            }else if(this.secondTabCurrentIndex === 3) {
                this.secondTabCurrentIndex = 3
                this.getFinacneDownload()
            }
        },

        async getTitleTabs() {
            const { code, data } =  await this.$http.get('/finacneTabs')
            if(code === 200) {
                this.tabsData = data
            }

            this.secondTabCurrentIndex = 0
            this.firstTabCurrentIndex = 0
        },

        async getFinacneDownload() {
            const { code, data } = await this.$http.get('/finacneDownload')
            if(code === 200) {
                this.downloadData = data
            }
        },

        async getFinanceIntroduce() {
            const { code, data } = await this.$http.get('/financeIntroduce')
            if(code === 200) {
                this.introduceData = data
            }
        },

        async getFinanceTeach() {
            const { code, data } = await this.$http.get('/financeTeach')
            if (code === 200) {
                this.teachData = data
            }
        },

        async getFinanceProblem() {
            const { code, data } = await this.$http.get('/financeProblem')
            if (code === 200) {
                this.problemData = data
            }
        }
    },

    components: {
        Breadcrumb,
        TitleTabs,
        TabItems,
        Introduce,
        Teach,
        Problem,
        Download
    },
};
</script>

<style lang="less" scoped>

</style>